
export const ADDRESS = {
    ETHEREUM: {
      STETH: "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84",
      ETHWIN: "0x987a99Edba69D6F19d2dA57a4f3A3B030a714122",
      SPETHWIN: "0xD5f60154BEf3564EbFBe9bB236595f0da548a742",
      PRIZEPOOL: "0x2758b8d894b08342f0d00ac5f9466fdc795e4618",
      PRIZESTRATEGY: "0xdef81ee763a8971283f12294e07d17df6658dbe5",
      // CBETH: "0xBe9895146f7AF43049ca1c1AE358B0541Ea49704",
      // CBETHWIN: "0xd1a9C1a5B62AD430a4FA6e690B078eFF48a1Fe71",
      // CBSPETHWIN: "0x782d33D3B16c7E89D6B970252e269D262B42eD0d",
      // CBPRIZEPOOL: "0x590A078F069DfbBF98ae5A21c34ef3d9A59e6fC4",
      // CBPRIZESTRATEGY: "0x7C14f11E9Bb0b4B7b6213703D15EA8A82E2cE136",
      ENS: "0x3671aE578E63FdF66ad4F3E12CC0c0d71Ac7510C"
    },
    GOERLI: {
        STETH: "0x1643E812aE58766192Cf7D2Cf9567dF2C37e9B7F",
        ETHWIN: "0x0dD98DC0514D0659244F76118D4a31e815016043",
        SPETHWIN: "0x1EaeFed482A09B7e1A3d6281FdE9e7Aabf42D1F2",
        PRIZEPOOL: "0x6174783F6196a325350bF8E3C897DE00ab8E2186", 
        PRIZESTRATEGY: "0xF201911C77bdff5D90fdAa5a3ca910DC880A316d",
    }
  };

  export const URL = {
    ETHEREUM: {
      GRAPH: "https://api.thegraph.com/subgraphs/name/pooltogether/pooltogether-v3_4_3"
    },
    GOERLI: {
      GRAPH: ""
    }
  }